import {trimObject} from "@/utils/helpers";
import {HttpService} from "./http.service";
import {UpdateOrderProductRequestDto} from "@/resources/dtos/order-products/update-order-product.dto";
import {OrderProductModel} from "@/resources/models";

export class OrderProductsService {

  static patch(id: number, data: UpdateOrderProductRequestDto, showSpinner = false) {
    return HttpService.patch(`/order-products/${id}`, trimObject(data, [undefined]), showSpinner)
      .then((res) => new OrderProductModel(res));
  }

  static changeBin(id: number, binId: number, showSpinner = false) {
    return HttpService.patch(`/order-products/${id}/change-bin/${binId}`, {}, showSpinner)
      .then((res) => new OrderProductModel(res));
  }

}
