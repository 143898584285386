import { FC, useState } from "react";
import { Trans } from "react-i18next";
import { Divider, Menu, MenuItem } from "@mui/material";
import { InboxIcon, MoreVertIcon } from "@/icons";

export type MenuAction = "editOrderProductComment" | "removeOrderProduct" | "changeBin";

export interface IMenuProps {
  onChange(action?: MenuAction): void;
}

export const OrderProductMenu: FC<IMenuProps> = ({ onChange }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();

  const onSelect = (menuAction: MenuAction) => {
    onChange(menuAction);
    setAnchorEl(undefined);
  };

  return (
    <>
      <button className={"icon-btn btn-sm"} onClick={(e) => setAnchorEl(e.currentTarget)}>
        <MoreVertIcon />
      </button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={() => setAnchorEl(undefined)}
      >
        <MenuItem onClick={() => onSelect("editOrderProductComment")} disableRipple>
          <i className="fa fa-edit mr-2 ml-1" />
          <Trans i18nKey="repairs.editComment" />
        </MenuItem>
        <MenuItem onClick={() => onSelect("changeBin")} disableRipple>
          <InboxIcon className="mr-2" />
          <Trans i18nKey="repairs.changeBin" />
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => onSelect("removeOrderProduct")} disableRipple>
          <i className="fa fa-trash mr-2 ml-1" />
          <Trans i18nKey="repairs.deletedProduct" />
        </MenuItem>
      </Menu>
    </>
  );
};
