export class ProductModel {
  name: string;
  labels: number;
  price: number;
  product_id?: string;
  image?: {
    url: string;
    alt?: string;
    copyright?: string;
    dimensions: {
      width: number;
      height: number;
    };
  };

  constructor(init?: Partial<ProductModel>) {
    const data = {
      name: '',
      labels: 0,
      price: 0,
      ...init,
    };

    this.name = data.name;
    this.labels = data.labels;
    this.price = data.price;
    this.image = data.image;
    this.product_id = data.product_id; 
  }
}
