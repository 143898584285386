import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomDrawer } from "@/components";
import { RepairService, ToastService } from "@/services";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { RepairModel } from "@/resources/models";
import { useAppSelector } from "@/redux/hooks";
import DymoPrint from "./DymoPrint";

export interface IActionsPanelProps {
  repair: RepairModel;
  onClose(): void;
  isOpen: boolean;
}

type view = "default" | "deleteRepairConfirmation";

const ActionsPanel: FC<IActionsPanelProps> = ({
  repair,
  isOpen,
  onClose,
}) => {
  const navigate = useNavigate();

  const [view, setView] = useState<view>("default");
  const { user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    setView("default");
  }, [isOpen]);

  const { t } = useTranslation();

  const removeRepair = useCallback(async () => {
    try {
      await RepairService.remove(repair.id);
      ToastService.success(t("repairs.deletedMessage"));
      navigate("/repairs");
    } catch (err: unknown) {
      if (err instanceof AxiosError || err instanceof Error) {
        ToastService.showHttpError(err, t("common.operationFailedMessage"));
      }
    }
  }, [repair, navigate, t]);

  return (
    <CustomDrawer
      isOpen={isOpen}
      title={t("repairs.repair") || ""}
      position="end"
      contentClass="p-8"
      onClose={onClose}
    >
      <div className="flex flex-col items-start gap-6">
        {/** PRINT LABEL */}
        {view === "default" && (
          <div>
            <DymoPrint repair={repair} />
          </div>
        )}
        {/** DELETE REPAIR */}
        {(user?.isAdmin() && view === "default" || view === "deleteRepairConfirmation") && (
            <div className="flex flex-col items-start gap-6">
              {
                <button
                  type="submit"
                  className="btn btn-blue"
                  onClick={() => {
                    setView("deleteRepairConfirmation");
                  }}
                  disabled={view === "deleteRepairConfirmation"}
                >
                  {t("repairs.deleteRepair")}
                </button>
              }
              {view === "deleteRepairConfirmation" && (
                <div>
                  <p className="mt-4 mb-4">
                    {t("repairs.deletionConfirmMessage")}
                  </p>
                  <button
                    type="submit"
                    className="btn bg-danger-light text-danger-dark"
                    onClick={() => {
                      removeRepair();
                    }}
                  >
                    {t("repairs.deleteRepair")}
                  </button>
                </div>
              )}
            </div>
          )}
      </div>
    </CustomDrawer>
  );
};

export default ActionsPanel;
