import { BinInfo } from "@/hooks/bins/useAvailableBins";
import { FC } from "react";
import classnames from "classnames";
import { BinModel } from "@/resources/models";

interface BinCardProps {
  binInfo: BinInfo;
  onSelect(bin: BinModel | undefined): void;
  activeBin: BinModel|undefined;
}

export const BinCard: FC<BinCardProps> = ({ binInfo, onSelect, activeBin }) => (
  <div
    key={binInfo.content}
    className={classnames(
      "w-65 h-full flex flex-col border rounded text-center p-4 transition-all",
      activeBin?.id === binInfo.bin?.id ? "border-2 border-blue" : "border-secondary hover:border-blue",
      binInfo.bin ? "cursor-pointer" : "opacity-60 cursor-default"
    )}
    onClick={() => onSelect(binInfo.bin)}
  >
    <div className="text-lg font-bold uppercase">{binInfo.content} Liters</div>
    <div className="font-bold mb-1">{binInfo.bin?.name}</div>
    <img className="w-40 m-auto" src={binInfo?.image} alt={`${binInfo.content}`} />
  </div>
);
