import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomDrawer } from "@/components";
import { OrderProductModel, RepairModel } from "@/resources/models";
import { OrderProductCard } from "./OrderProductCard";

export interface IActionsPanelProps {
  repair: RepairModel;
  orderProduct: OrderProductModel | null;
  onClose: (reloadOrderProducts: boolean) => void;
  onRemove(repair: RepairModel, orderProduct: OrderProductModel): void;
  isOpen: boolean;
}

type view = "default" | "deleteConfirmation";

export const RemoveProduct: FC<IActionsPanelProps> = ({ repair, orderProduct, isOpen, onClose, onRemove }) => {
  const { t } = useTranslation();

  const [view, setView] = useState<view>("default");

  useEffect(() => {
    setView("default");
  }, [isOpen]);

  return (
    <CustomDrawer
      isOpen={isOpen}
      title={t("repairs.deletedProduct") || ""}
      position="end"
      contentClass="p-8"
      size="lg"
      onClose={() => {
        onClose(false);
      }}
    >
      {orderProduct && (
        <div className="flex flex-col items-start gap-6">
          <OrderProductCard orderProduct={orderProduct} />
          <div>
            <button
              className="btn btn-blue"
              onClick={() => {
                setView("deleteConfirmation");
              }}
              disabled={view === "deleteConfirmation"}
            >
              {t("repairs.deletedProduct")}
            </button>

            {view === "deleteConfirmation" && (
              <div>
                {" "}
                <p className="mt-4 mb-4">{t("repairs.deletedProductConfirmMessage")}</p>
                <button
                  type="submit"
                  className="btn bg-danger-light text-danger-dark"
                  onClick={() => {
                    onRemove(repair, orderProduct);
                  }}
                >
                  {t("repairs.deletedProduct")}
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </CustomDrawer>
  );
};
