import { useCallback } from "react";
import { OrderProductModel, ProductModel, RepairModel } from "@/resources/models";
import { printLabel } from "@/hooks/dymo";
import { ToastService } from "@/services";
import {
  IDymoPrintParams,
  IDymoPrintStatus,
  LabelSize,
  PrinterModelAndParms,
} from "@/resources/interfaces/dymo.interface";
import { formatDate } from "@/utils/helpers";
import { labelToPrinterMap } from "./constants";
import { dymoLargeLabel } from "./dymoLabelLarge";
import { dymoMediumLabel } from "./dymoLabelMedium";
import { dymoSmallLabel } from "./dymoLabelSmall";
import { useTranslation } from "react-i18next";

function getOrderProductSummary(
  orderProducts: Array<OrderProductModel>,
  allProducts: Partial<ProductModel>[]
): Array<string> {
  const lines = new Map<string, number[]>(); // where k = OrderProduct.name + Product id, v = OrderProduct.id
  orderProducts.forEach((orderProduct: OrderProductModel) => {
    const productId = findPrismicProductId(orderProduct, allProducts);
    const k = `${orderProduct.name} - ${productId}`;
    const v = orderProduct.id;
    if (v) {
      if (lines.get(k)) {
        lines.get(k)?.push(v);
      } else {
        lines.set(k, [v]);
      }
    }
  });
  return Array.from(lines.entries()).map(([k, v]) => `${v.length}x ${k} - ${v.sort().join("/")}`);
}

function getFirstAvailablePrinterModel(
  printerModels: PrinterModelAndParms[] | undefined,
  availablePrinters: IDymoPrintStatus[]
): {
  availablePrinter: IDymoPrintStatus;
  printerModel: PrinterModelAndParms;
} | null {
  if (!printerModels) {
    return null;
  }
  for (const printerModel of printerModels) {
    const printerStatus = availablePrinters.find(
      (printer) => printer.modelName === printerModel.printerModel && printer.isConnected === "True"
    );
    if (printerStatus) {
      return { availablePrinter: printerStatus, printerModel: printerModel };
    }
  }
  return null;
}

function findPrismicProductId(orderProduct: OrderProductModel, allProducts: Partial<ProductModel>[]) {
  if (orderProduct.cmsProductId) {
    return orderProduct.cmsProductId;
  }
  return allProducts.find((item) => item.name === orderProduct.name)?.product_id;
}

export const usePrintLabelsCallback = (
  repair: RepairModel | undefined,
  printers: IDymoPrintStatus[],
  binId: number | undefined,
  allProducts: Partial<ProductModel>[]
) => {
  const { t } = useTranslation();

  async function handlePrintSingleLabel(
    printerName: string,
    printerParams: IDymoPrintParams | null,
    labelXml: string,
    labelSize: LabelSize
  ) {
    try {
      await printLabel(printerName, labelXml, "", printerParams);
      ToastService.success(`Printing ${labelSize} label to ${printerName} complete`);
    } catch (error) {
      ToastService.error(`Printing ${labelSize} label to ${printerName} failed`);
    }
  }

  return useCallback(async () => {
    if (!repair || !repair.products || repair.products.length === 0 || typeof binId === "undefined") {
      return;
    }
    const availablePrinters = printers.filter((curr: any) => curr && curr.isConnected.toLowerCase() === "true");
    const labelDate: string = formatDate(repair.startUnpackingAt, "label");
    const productsForBin =
      typeof binId === "number" ? repair.products.filter((item) => item.binId === binId) : repair.products;
    const deliveryMethod = repair.deliveryMethod
      ? t(`common.deliveryMethods.${repair.deliveryMethod}`) || repair.deliveryMethod
      : "";

    // Small labels
    const smallLabelPrinter = getFirstAvailablePrinterModel(labelToPrinterMap.get("small"), availablePrinters);
    if (smallLabelPrinter) {
      for await (const orderProductModel of productsForBin) {
        const qrCodeDataString = JSON.stringify({
          order: repair.id,
          orderProduct: orderProductModel.id,
        });
        const labelXml = dymoSmallLabel(`${orderProductModel.bin?.name}\n${orderProductModel.id}`, qrCodeDataString);
        // A Product can be added multiple times in one OrderProduct. Print a label for each product.
        for (let i = 0; i < orderProductModel.count; i++) {
          // Use await because DymoPrintService crashes if to many requests are send at once.
          await handlePrintSingleLabel(
            smallLabelPrinter.availablePrinter.name,
            smallLabelPrinter.printerModel.printerParams || null,
            labelXml,
            "small"
          );
        }
      }
    } else {
      ToastService.warning(`No printer available for small labels`);
    }
    // Medium labels
    const mediumLabelPrinter = getFirstAvailablePrinterModel(labelToPrinterMap.get("medium"), availablePrinters);
    if (mediumLabelPrinter) {
      for await (const orderProductModel of productsForBin) {
        const qrCodeDataString = JSON.stringify({
          order: repair.id,
          orderProduct: orderProductModel.id,
        });
        const labelXml = dymoMediumLabel(
          `Klantnr + naam: ${repair.customer?.customerNumber || ""} ${repair.customer?.fullName}`,
          `Type + jaar: ${repair.brand || ""}`,
          `Leveringsmethode: ${deliveryMethod}`,
          `Baknr: ${orderProductModel.bin?.name}`,
          ``,
          qrCodeDataString,
          labelDate
        );
        // Use await because DymoPrintService crashes if to many requests are send at once.
        await handlePrintSingleLabel(
          mediumLabelPrinter.availablePrinter.name,
          mediumLabelPrinter.printerModel.printerParams || null,
          labelXml,
          "medium"
        );
      }
    } else {
      ToastService.warning(`No printer available for medium labels`);
    }
    // Large labels
    const largeLabelPrinter = getFirstAvailablePrinterModel(labelToPrinterMap.get("large"), availablePrinters);
    if (largeLabelPrinter) {
      const binNames = Array.from(new Set(repair.products.map((item) => item.bin?.name)))
        .sort()
        .join(", ");
      const qrCodeDataString = JSON.stringify({
        order: repair.id,
      });
      const labelXml = dymoLargeLabel(
        [
          `Klantnr + naam: ${repair.customer?.customerNumber || ""} ${repair.customer?.fullName}`,
          `Type + jaar: ${repair.brand || ""}`,
          `Leveringsmethode: ${deliveryMethod}`,
          `Baknr: ${binNames}`,
          ``,
          getOrderProductSummary(repair.products, allProducts).join("\n"),
          ``,
          `Opmerking: ${repair.comment}`,
        ].join("\n"),
        qrCodeDataString,
        labelDate
      );

      // Use await because DymoPrintService crashes if to many requests are send at once.
      await handlePrintSingleLabel(
        largeLabelPrinter.availablePrinter.name,
        largeLabelPrinter.printerModel.printerParams || null,
        labelXml,
        "large"
      );
    } else {
      ToastService.warning(`No printer available for large labels`);
    }
  }, [repair, printers, binId, allProducts, t]);
};
