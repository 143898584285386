import { useDymoCheckService, useDymoFetchPrinters } from "@/hooks/dymo";
import { AlertIcon } from "@/icons/Alert";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { usePrintLabelsCallback } from "./PrintLabelsHook";
import { IDymoPrinters } from "@/resources/interfaces/dymo.interface";
import { RepairModel } from "@/resources/models";

export interface IDymoPrintProps {
  repair: RepairModel | undefined;
}

export const DymoPrint: FC<IDymoPrintProps> = ({ repair }) => {
  const statusDymoService = useDymoCheckService();
  const { printers }: IDymoPrinters = useDymoFetchPrinters(statusDymoService);
  const { t } = useTranslation();
  const onPrint = usePrintLabelsCallback(repair, printers);

  return (
    <div>
    {/** BUTTON **/}
    <button 
      className="btn btn-blue" 
      disabled={!repair || statusDymoService === "error"}
      onClick={onPrint}
    >
      {t("customers.printCustomerLabel")}
    </button>

    {/** STATUS **/}
    {statusDymoService === "error" && (
      <span className="flex-center text-danger mr-2 mt-2">
        <AlertIcon size={16} className="mr-2" />
        {t("dymowebservice.error")}
      </span>
    )}

    </div>
  );
};

export default DymoPrint;
