import { FC } from "react";
import { Trans } from "react-i18next";
import { OrderProductModel } from "@/resources/models";

export interface IActionsPanelProps {
  orderProduct: OrderProductModel | null;
}

export const OrderProductCard: FC<IActionsPanelProps> = ({ orderProduct }) => {
  if (!orderProduct) {
    return null;
  }
  return (
    <div className="flex flex-row items-start gap-6 w-full">
      <div>
        <h6>
          <Trans i18nKey="common.bin" />
        </h6>
        {orderProduct.bin?.name}
      </div>
      <div>
        <h6>
          <Trans i18nKey="common.image" />
        </h6>
        <img className="w-24" src={orderProduct.image} alt={`${orderProduct.id}`} />
      </div>
      <div className="span-4">
        <h6>
          <Trans i18nKey="common.repairNumber" />
        </h6>
        {orderProduct.id}
      </div>
      <div>
        <h6>
          <Trans i18nKey="common.components" />
        </h6>
        {orderProduct.name}
      </div>
    </div>
  );
};
