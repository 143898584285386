import { useState, useEffect } from "react";
import {BinService, ToastService} from "@/services";
import {DELIVERY_METHOD} from "@/resources/enums";
import {BIN_CONTENTS} from "@/constants";
import {BinModel} from "@/resources/models";

export interface BinInfo {
  content: number;
  image: string|undefined;
  bin?: BinModel;
  key: string;
}

export const useAvailableBins = (clearBins: boolean, deliveryMethod: DELIVERY_METHOD, t: (key: string) => string):Array<BinInfo> => {
  const [bins, setBins] = useState<BinInfo[]>([]);

  useEffect(() => {
    if (!clearBins) {
      setBins([]);
      return;
    }

    BinService.getAvailableBinsByContent(deliveryMethod)
      .then((data) => {
        const bins = BIN_CONTENTS
          .filter((item) => item.deliveryMethods.includes(deliveryMethod))
          .map((item, idx) => ({
            content: item.content,
            image: item.image,
            bin: data.find((curr) => curr.content === item.content),
            key: `bin-info-${idx}`,
          }));

        setBins(bins);
      })
      .catch((err) => {
        ToastService.showHttpError(err, t("bins.loadingFailedMessage"));
      });
  }, [clearBins, deliveryMethod, t]);

  return bins;
};

