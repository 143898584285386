import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomDrawer } from "@/components";
import { OrderProductModel, RepairModel } from "@/resources/models";
import { TextareaAutosize, TextField } from "@mui/material";

export interface IActionsPanelProps {
  repair: RepairModel;
  orderProduct: OrderProductModel;
  onClose: () => void;
  onSubmit(repair: RepairModel, orderProduct: OrderProductModel, comment: string): void;
  isOpen: boolean;
}

export const EditComment: FC<IActionsPanelProps> = ({ repair, orderProduct, isOpen, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [charCount, setCharCount] = useState((orderProduct.comment || "").length);
  const maxCharCount = 500;

  const onLocalSubmit = () => {
    if (inputRef.current) {
      onSubmit(repair, orderProduct, inputRef.current.value);
    }
  };

  const handleInput = () => {
    if (inputRef.current) {
      setCharCount(inputRef.current.value.length);
    }
  };

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  return (
    <CustomDrawer
      isOpen={isOpen}
      title={t("repairs.repairDetail") || ""}
      position="end"
      size="lg"
      contentClass="p-8"
      onClose={onClose}
    >
      {/** MODEL */}
      <div className="flex flex-col items-start gap-6 w-full">
        <div className="w-full">
          <h6>{t("common.remark")}</h6>
          <div className="form-field mt-1">
            <TextField
              defaultValue={orderProduct.comment || ""}
              inputRef={inputRef}
              InputProps={{
                inputComponent: TextareaAutosize,
              }}
              onInput={handleInput}
              error={charCount > maxCharCount}
              helperText={`${charCount} / ${maxCharCount}`}
            />
          </div>
        </div>
        <div>
          <button type="submit" className="btn btn-blue" onClick={onLocalSubmit}>
            {t("repairs.editComment")}
          </button>
        </div>
      </div>
    </CustomDrawer>
  );
};
