import { BarcodeData } from "./types";

const BarcodeInputProcessor = {
  process: (input: string[]): BarcodeData | null => {
    try {
      const data = input.join("").trim();
      if (data[0] === "{" && data[data.length - 1] === "}") {
        // Parse input as JSON
        const parsedInput = JSON.parse(input.join(""));
        console.info("[barcode-scanner]", parsedInput);
        return parsedInput;
      }
      return null;
    } catch (error) {
      // Handle parsing errors (invalid JSON)
      console.warn("Invalid barcode data:", error);
    }

    return null;
  },
};

export default BarcodeInputProcessor;
