import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomDrawer } from "@/components";
import { ToastService } from "@/services";
import { AxiosError } from "axios";
import { RepairModel } from "@/resources/models";
import { OrderImageModel } from "@/resources/models/order-image.model";
import { OrderImagesService } from "@/services/order-images.service";

export interface IActionsPanelProps {
  repair: RepairModel;
  onClose: (reloadImages:boolean) => void;
  isOpen: boolean;
  orderImage: OrderImageModel | null;
}

type view = "default" | "deleteConfirmation";

export const ImageActionsPanel: FC<IActionsPanelProps> = ({
  repair,
  isOpen,
  onClose,
  orderImage,
}) => {
  const [view, setView] = useState<view>("default");

  useEffect(() => {
    setView("default");
  }, [isOpen]);

  const { t } = useTranslation();

  const removeRepairImage = useCallback(async () => {
    if (!orderImage) {
      return;
    }
    try {
      await OrderImagesService.remove(repair.id, orderImage.id);
      ToastService.success(t("repairs.deletedImageMessage"));
      onClose(true);
    } catch (err: unknown) {
      if (err instanceof AxiosError || err instanceof Error) {
        ToastService.showHttpError(err, t("common.operationFailedMessage"));
      }
    }
  }, [repair, t, orderImage]);

  return (
    <CustomDrawer
      isOpen={isOpen}
      title={t("repairs.repair") || ""}
      position="end"
      contentClass="p-8"
      onClose={() => {onClose(false)}}
    >
      <div className="flex flex-col items-start gap-6">
        {/** ORDER IMAGE */}
        <div className="flex flex-col items-start gap-6">
          {orderImage && (
            <img
              src={orderImage.thumbnailUrl}
              alt={`${orderImage.id}`}
              className="rounded-sm w-50"
            />
          )}

          {
            <button
              type="submit"
              className="btn btn-blue"
              onClick={() => {
                setView("deleteConfirmation");
              }}
              disabled={view === "deleteConfirmation"}
            >
              {t("repairs.deleteImage")}
            </button>
          }

          {view === "deleteConfirmation" && (
            <div>
              <p className="mt-4 mb-4">
                {t("repairs.deleteImageConfirmMessage")}
              </p>
              <button
                type="submit"
                className="btn bg-danger-light text-danger-dark"
                onClick={() => {
                  removeRepairImage();
                }}
              >
                {t("repairs.deleteImage")}
              </button>
            </div>
          )}
        </div>
      </div>
    </CustomDrawer>
  );
};
