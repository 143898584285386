import {RepairModel} from "@/resources/models/repair.model";
import {BinModel} from "@/resources/models/bin.model";

export interface ProductFormData {
  name: string;
  image?: string;
  price: number;
  count: number;
  cmsProductId: string; // prismic product id
}

export class OrderProductModel implements ProductFormData {
  id: number;
  orderId: string;
  binId?: number;
  orderBinId?: string;
  name: string;
  image?: string;
  price: number;
  count: number;
  createdAt: string;
  updatedAt: string;
  cmsProductId: string; // prismic product id
  order?: RepairModel;
  bin?: BinModel;
  comment: string;

  constructor(init?: Partial<OrderProductModel>) {
    const data = {
      id: 0,
      orderId: '',
      name: '',
      price: 0,
      count: 0,
      createdAt: '',
      updatedAt: '',
      cmsProductId: '',
      comment: '',
      ...init,
    };

    this.id = data.id;
    this.orderId = data.orderId;
    this.binId = data.binId;
    this.orderBinId = data.orderBinId;
    this.name = data.name;
    this.image = data.image;
    this.price = data.price;
    this.count = data.count;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.cmsProductId = data.cmsProductId;
    this.comment = data.comment || '';

    if (data.order) {
      this.order = new RepairModel(data.order);
    }
    if (data.bin) {
      this.bin = new BinModel(data.bin);
    }
  }

  static asHtmlId(id:number|string):string {
    return `order-product-${id}`;
  }
}
