import axios, {AxiosError, AxiosRequestConfig, Method } from "axios";
import { CONFIG } from "@/constants";
import { store } from "@/redux/store";
import { setToken } from "@/redux/reducers/auth.reducer";
import { finishLoading, startLoading } from "@/redux/reducers/spinner.reducer";
import { getCurrentLanguage } from "@/utils/helpers";

const http = axios.create({
  baseURL: CONFIG.API_SERVER,
  headers: {
    "Content-type": "application/json",
    // 'Cache-Control': 'no-cache',
    // 'Pragma': 'no-cache',
    // 'Expires': '0',
  },
});

http.interceptors.request.use((req) => {
  const rootState = store.getState();
  const language = getCurrentLanguage();
  if (language) {
    req.headers.set('x-custom-lang', language);
  }
  if (rootState.auth.token) {
    req.headers.set('Authorization', `Bearer ${rootState.auth.token}`);
  }
  return req;
});

http.interceptors.response.use((res) => {
  return res.data;
}, (err: AxiosError) => {
  if (err.response?.status === 401 && !err.config?.url?.startsWith('/auth')) {
    store.dispatch(setToken(null));
    window.location.href = '/login';
  }
  throw err;
});

export class HttpService {
  static get(url: string, queries: any = {}, showSpinner = true) {
    return HttpService.request('GET', url, { params: queries }, showSpinner);
  }

  static post(url: string, data: any = {}, showSpinner = true) {
    return HttpService.request('POST', url, { data }, showSpinner);
  }

  static put(url: string, data: any = {}, showSpinner = true) {
    return HttpService.request('PUT', url, { data }, showSpinner);
  }

  static patch(url: string, data: any = {}, showSpinner = true) {
    return HttpService.request('PATCH', url, { data }, showSpinner);
  }

  static delete(url: string, data: any = {}, showSpinner = true) {
    return HttpService.request('DELETE', url, { data }, showSpinner);
  }

  static upload(url: string, files: File[], showSpinner = true) {
    const formData = new FormData();
    
    files.forEach((file) => {
      formData.append('images', file);
    });

    return HttpService.request('POST', url, {
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }, showSpinner);
  }

  static request(method: Method, url: string, data?: AxiosRequestConfig, showSpinner = true): Promise<any> {
    if (showSpinner) {
      store.dispatch(startLoading());
    }
    return http.request({
      method,
      url,
      ...data,
    }).finally(() => {
      if (showSpinner) {
        store.dispatch(finishLoading());
      }
    });
  }
}

