import {useCallback, useEffect, useRef, useState} from "react";
import {debounce} from "lodash";
import classnames from "classnames";
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {TextareaAutosize, TextField} from "@mui/material";
import {Chip} from "@/components";
import {ArrowLeftIcon, ArrowRightIcon, MoreVertIcon, TimeOutlineIcon} from "@/icons";
import FullLayout from "@/layouts/FullLayout";
import RepairDetailTable from "@/pages/Repairs/Detail/DetailTable";
import RepairHistoryTable from "@/pages/Repairs/Detail/HistoryTable";
import AddProductsPanel from "@/pages/Repairs/Detail/AddProductsPanel";
import {useAppSelector} from "@/redux/hooks";
import {UpdateOrderRequestDto} from "@/resources/dtos/orders/update-order.dto";
import {
  DELIVERY_METHOD,
  DELIVERY_METHODS,
  ORDER_TYPES,
  PAYMENT_STATUS,
  PAYMENT_STATUSES,
  REPAIR_STATUS,
  REPAIR_STATUS_OPTIONS,
  YES_NO_OPTIONS
} from "@/resources/enums";
import {OrderLogModel, OrderProductModel, ProductFormData, ProductModel, RepairModel} from "@/resources/models";
import {OrderLogService, PrismicService, RepairService, ToastService} from "@/services";
import {formatDate} from "@/utils/helpers";
import { DymoPrint } from "./DymoPrint";
import {usePrintLabelsCallback} from "./DymoPrint/PrintLabelsHook";
import {IDymoPrinters} from "@/resources/interfaces/dymo.interface";
import {useDymoCheckService, useDymoFetchPrinters} from "@/hooks/dymo";
import ActionsPanel from "./ActionsPanel";
import {OrderImageModel} from "@/resources/models/order-image.model";
import {OrderImagesService} from "@/services/order-images.service";
import Uploads from "./Uploads";
import {ImageActionsPanel} from "./ImageActionsPanel";
import {MenuAction} from "./OrderProductMenu";
import {ChangeBin} from "./OrderProductPanels/ChangeBin";
import {EditComment} from "./OrderProductPanels/EditComment";
import {RemoveProduct} from "./OrderProductPanels/RemoveProduct";
import {AxiosError} from "axios";
import {OrderProductsService} from "@/services/order-products.service";

const updateOrder = debounce((id: string, data: UpdateOrderRequestDto, callback: () => void ) => {
  return RepairService.patch(id, data, false).then(callback);
}, 1000);


type panels = "none" | "addProducts" | "actions" | "imageActions" | "removeOrderProduct" | "changeBin" | "editOrderProductComment";

const RepairDetail = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();
  const { selectedStatus } = useAppSelector((state) => state.repair);
  const [data, setData] = useState<RepairModel>();
  const [orderLogs, setOrderLogs] = useState<OrderLogModel[]>([]);
  const [orderImages, setOrderImages] = useState<OrderImageModel[]>([]);
  const statusDymoService = useDymoCheckService();
  const {printers}:IDymoPrinters = useDymoFetchPrinters(statusDymoService);
  const [printLabelsForBin, setPrintLablesForBin] = useState<number|undefined>();
  const [showPanel, setShowPanel] = useState<panels>("none");
  const [selectedOrderImage, setSelectedOrderImage] = useState<OrderImageModel|null>(null);
  const [selectedOrderProduct, setSelectedOrderProduct] = useState<OrderProductModel|null>(null);
  const startedAt = useRef(new Date());
  const [allProducts, setAllProducts] = useState<Partial<ProductModel>[]>([]); // list of all products from prismic
  const [searchParams] = useSearchParams();
  const orderProductId = searchParams.get("order_product"); // Get product ID from query string


  useEffect(() => {
    if (!id) {
      return;
    }
    RepairService.find(id).then((data) => {
      setData(data);
    }).catch((err) => {
      ToastService.showHttpError(err, 'Loading repair order failed');
    });
  }, [id]);

  const loadOrderHistory = useCallback((showSpinner?: boolean) => {
    OrderLogService.search({ orderId: id, sort: '-createdAt' }, showSpinner).then((data) => {
      setOrderLogs(data.data);
    }).catch((err) => {
      ToastService.showHttpError(err, 'Loading order logs failed');
    });
  }, [id]);

  const loadOrderProducts = useCallback(async () => {
    if (typeof id === "string") {
      try {
        // @TODO add endpoint and state for OrderProducts
        const data = await RepairService.find(id, false);
        setData(data);
      } catch(err) {
        if (err instanceof AxiosError || err instanceof Error) {
          ToastService.showHttpError(err, 'Loading order logs failed');
        }
      }
    }
  }, [id]);

  const loadOrderImages = useCallback(async () => {
    if (!id) {
      return;
    }

    try {
      const orderImages =  await OrderImagesService.findForOrder(id, false);
      if (orderImages) {
        setOrderImages(orderImages);
      }
    } catch(err) {
      ToastService.showHttpError(err as Error, 'Loading order images failed');
    };
  }, [id]);

  useEffect(() => {
    loadOrderHistory(true);
    loadOrderImages();
  }, [loadOrderHistory, loadOrderImages]);

  useEffect(() => {
    async function fetchProducts() {
      try {
        const data = await PrismicService.searchProducts(false);
        const products = data.map((item) => ({
          name: item.name,
          product_id: item.product_id,
        }));
        setAllProducts(products);
      } catch(err:any|Error) {
        ToastService.showHttpError(err, t("common.operationFailedMessage"));
      }
    }
    fetchProducts();
  }, [t]);

  const onClose = () => {
    navigate(-1);
  };

  const onFieldChange = useCallback((field: keyof RepairModel, value: any) => {
    const updatedData = new RepairModel({
      ...data,
      [field]: value,
    });
    setData(updatedData);

    if (field !== 'comment' && (updatedData.comment || '').length <= 500) {
      updateOrder(id!, {
        deliveryMethod: updatedData.deliveryMethod,
        urgent: updatedData.urgent,
        paymentStatus: updatedData.paymentStatus,
        proforma: updatedData.proforma,
        internal: updatedData.internal,
        brand: updatedData.brand,
        type: updatedData.type,
        guarantee: updatedData.guarantee,
        comment: updatedData.comment,
        internalComment: updatedData.internalComment,
      }, () => {
        loadOrderHistory(false);
      });
    }
  }, [id, data, loadOrderHistory]);

  const onCompleteStatus = () => {
    if (!data) {
      return;
    }

    RepairService.completeStatus(data.id, { startedAt: startedAt.current.toISOString() }).then((res) => {
      setData(new RepairModel({
        ...data,
        ...res,
      }));
      onClose();
    });
  };

  const onAddProducts = (binId: number, products: ProductFormData[]) => {
    RepairService.addProducts(id!, { binId, products }).then((res) => {
      setData(new RepairModel({
        ...data,
        products: [...data!.products, ...res],
      }));
      setShowPanel("none");
      loadOrderHistory(false);
      setPrintLablesForBin(binId);
    }).catch((err) => {
      ToastService.showHttpError(err, 'Saving your changes failed');
    });
  };

  const onRemoveOrderProduct = useCallback(async (order: RepairModel, orderProduct: OrderProductModel) => {
    setSelectedOrderProduct(null);
    setShowPanel("none");
    try {
      // Remove OrderProduct from Order
      await RepairService.removeProduct(order.id, orderProduct.id);
      // Get updated Order
      if (id) {
        const repair = await RepairService.find(id);
        if (repair) {
          setData(repair);
        }
        loadOrderHistory(false);
      }
    }  catch (err) {
      console.error(err);
      if (err instanceof AxiosError || err instanceof Error) {
        ToastService.showHttpError(err, 'Loading repair order failed');
      }
    }
  }, [id, loadOrderHistory]);

  const onChangeBin = async (order: RepairModel, orderProduct: OrderProductModel, binId: number) => {
    setSelectedOrderProduct(null);
    setShowPanel("none");
    await OrderProductsService.changeBin(orderProduct.id, binId);
    loadOrderProducts();
    loadOrderHistory(false);
  }

  const onChangeOrderProductComment = async (_order: RepairModel, orderProduct: OrderProductModel, comment: string) => {
    orderProduct.comment = comment;
    setSelectedOrderProduct(null);
    setShowPanel("none");
    await OrderProductsService.patch(orderProduct.id, orderProduct);
    loadOrderProducts();
    loadOrderHistory(false);
  }


  const printLabelsCallback = usePrintLabelsCallback(data, printers, printLabelsForBin, allProducts);
  useEffect(() => {
    if (printLabelsForBin) {
      printLabelsCallback();
    }
    setPrintLablesForBin(undefined);
  }, [data, printLabelsForBin, printLabelsCallback]);

  useEffect(() => {
    if (data && orderProductId) {
      const element = document.getElementById(OrderProductModel.asHtmlId(orderProductId));
      if (element) {
        element.scrollIntoView({ behavior: "auto" });
      }
    }
  }, [data, orderProductId]);

  useEffect(() => {
    // clear selected order image if panel closes
    if (showPanel === "none") {
      setSelectedOrderImage(null);
    }
  }, [showPanel]);

  function onOrderImageSelect(orderImage:OrderImageModel) {
    setSelectedOrderImage(orderImage);
    setShowPanel("imageActions");
  }

  function onOrderProductSelect(orderProduct:OrderProductModel, menuAction:MenuAction = 'editOrderProductComment') {
    setSelectedOrderProduct(orderProduct);
    if (menuAction === "editOrderProductComment") {
      setShowPanel("editOrderProductComment");
    } else if (menuAction === "removeOrderProduct") {
      setShowPanel("removeOrderProduct");
    } else if (menuAction === "changeBin") {
      setShowPanel("changeBin");
    }
  }

  function onOrderImageAdded() {
    loadOrderImages();
  }

  function onCloseImageActions(reloadImages:boolean=false) {
    setShowPanel("none");
    if (reloadImages) {
      loadOrderImages();
      //  @FIXME: requesting the orderImages directly after a delete includes the delete image. Using a short delay as temp fix
      window.setTimeout(() => {
        loadOrderImages();
      }, 500)
    }
  }

  return (
    <>
      <FullLayout
        header={(
          <>
            <button className="icon-btn" onClick={onClose}>
              <ArrowLeftIcon />
            </button>
            <h2 className="ml-4">{t('repairs.repair')}</h2>
            <span className="text-blue-400 ml-4">{data?.id || ''}</span>
            <button className="icon-btn ml-auto" onClick={() => setShowPanel("actions")}>
              <MoreVertIcon />
            </button>
            {data?.status === REPAIR_STATUS.REGISTRATION && (
              <button className="btn btn-blue ml-4" onClick={onCompleteStatus}>
                {t('repairs.completeRegistration')}
              </button>
            )}
            {data?.status === REPAIR_STATUS.UNPACKING && (
              <button className="btn btn-blue ml-4" onClick={onCompleteStatus}>
                {t('repairs.completeUnpacking')}
              </button>
            )}
            {data?.status === REPAIR_STATUS.BOOKING && (
              <button className="btn btn-blue ml-4" disabled={!data?.products?.length} onClick={onCompleteStatus}>
                {t('repairs.completeBooking')}
              </button>
            )}
            {data?.status === REPAIR_STATUS.WORKTABLE && (
              <button className="btn btn-blue ml-4" onClick={onCompleteStatus}>
                {t('repairs.completeRepair')}
              </button>
            )}
          </>
        )}
      >
        {data && (
          selectedStatus === REPAIR_STATUS.UNPACKING ? (
            <>
              <div className="card">
                <h2>{t('common.overview')}</h2>
                <div className="grid grid-cols-12 gap-6 mt-6">
                  <div className="col-span-6">
                    <h6>{t('repairs.startUnpacking')}</h6>
                    <div className="flex items-center mt-1">
                      <TimeOutlineIcon className="text-blue-400" size={20} />
                      <span className="lowercase ml-2.5">
                        {formatDate(data.startUnpackingAt || startedAt.current, 'full')}
                      </span>
                    </div>
                  </div>

                  <div className="col-span-6">
                    <h6>{t('common.caseNumber')}</h6>
                    <div className="flex items-center mt-1">{data.id}</div>
                  </div>
                </div>
              </div>

              <div className="card mt-10">
                <h2>{t('repairs.unpackDetails')}</h2>
                <p className="mt-6">{t('repairs.noUnpackedItems')}.</p>
              </div>
            </>
          ) : selectedStatus === REPAIR_STATUS.BOOKING ? (
            <>
              <div className="card">
                <h2>{t('common.overview')}</h2>
                <div className="grid grid-cols-12 gap-6 mt-6">
                  <div className="col-span-6">
                    <h6>{t('repairs.startBooking')}</h6>
                    <div className="flex items-center mt-1">
                      <TimeOutlineIcon className="text-blue-400" size={20} />
                      <span className="lowercase ml-2.5">
                        {formatDate(data.startBookingAt || startedAt.current, 'full')}
                      </span>
                    </div>
                  </div>

                  <div className="col-span-6">
                    <h6>{t('common.caseNumber')}</h6>
                    <div className="flex items-center mt-1">{data.id}</div>
                  </div>
                </div>
              </div>

              <div className="card mt-10">
                <div className="flex items-center justify-between">
                  <h2>{t('repairs.bookingDetails')}</h2>
                  <button className="btn btn-blue ml-auto" disabled={!data.deliveryMethod} onClick={() => setShowPanel("addProducts")}>
                    {t('repairs.addProducts')}
                  </button>
                </div>
                {data.products?.length ? (
                  <RepairDetailTable className="mt-6" repair={data} onSelect={onOrderProductSelect} />
                ) : (
                  <p className="mt-6">{t('repairs.noBookedItems')}.</p>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="flex">
                {REPAIR_STATUS_OPTIONS.map((option, i) => {
                  const disabled = option.value === REPAIR_STATUS.CLOSED;
                  return (
                    <div
                      key={i}
                      className={classnames(
                        'w-0 h-14 flex-center grow bg-white text-blue-400 text-center border border-blue-100 p-2 transition-all',
                        i === 0 && 'rounded-l',
                        i === REPAIR_STATUS_OPTIONS.length - 1 && 'rounded-r',
                        option.value === data.status && '!bg-blue-800 !text-white font-semibold',
                        disabled ? '!bg-blue-200 !text-blue-400 cursor-default' : 'hover:bg-blue-400 hover:text-white cursor-pointer',
                      )}
                      onClick={() => !disabled && onFieldChange('status', option.value)}
                    >
                      {option.text}
                    </div>
                  );
                })}
              </div>

              <div className="grid grid-cols-12 gap-6 mt-10">
                <div className="card col-span-12 md:col-span-7">
                  <h2>{t('common.overview')}</h2>
                  <div className="grid grid-cols-12 gap-6 mt-6">
                    <div className="col-span-6">
                      <h6>{t('common.receiptDate')}</h6>
                      <div className="flex items-center mt-1">
                        <TimeOutlineIcon className="text-blue-400" size={20} />
                        <span className="lowercase ml-2.5">{formatDate(data.createdAt, 'full')}</span>
                      </div>
                    </div>

                    <div className="col-span-6">
                      <h6>{t('common.caseNumber')}</h6>
                      <div className="flex items-center mt-1">{data.id}</div>
                    </div>

                    <div className="col-span-12">
                      <h6>{t('common.internal')}</h6>
                      <div className="flex items-center gap-1 mt-1">
                        {YES_NO_OPTIONS.map((option) => (
                          <Chip
                            key={option.text}
                            theme="blue-800"
                            variant="outline"
                            active={data.internal === option.value}
                            clickable
                            onClick={() => onFieldChange('internal', option.value)}
                          >
                            {option.text}
                          </Chip>
                        ))}
                      </div>
                    </div>

                    <div className="col-span-12">
                      <h6>{t('common.brand')} / {t('common.model')}</h6>
                      <div className="form-field mt-1">
                        <TextField
                          value={data.brand || ''}
                          onChange={(e) => onFieldChange('brand', e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-span-12">
                      <h6>{t('common.deliveryMethod')}</h6>
                      <div className="flex items-center flex-wrap gap-1 mt-1">
                        {DELIVERY_METHODS.map((option) => (
                          <Chip
                            key={option.value}
                            theme="blue-800"
                            variant="outline"
                            active={data.deliveryMethod === option.value}
                            clickable
                            onClick={() => onFieldChange('deliveryMethod', option.value)}
                          >
                            {option.text}
                          </Chip>
                        ))}
                      </div>
                    </div>

                    <div className="col-span-12">
                      <h6>{t('common.payInAdvance')}</h6>
                      <div className="flex items-center gap-1 mt-1">
                        {YES_NO_OPTIONS.map((option) => (
                          <Chip
                            key={option.text}
                            theme="blue-800"
                            variant="outline"
                            active={data.proforma === option.value}
                            clickable
                            onClick={() => onFieldChange('proforma', option.value)}
                          >
                            {option.text}
                          </Chip>
                        ))}
                      </div>
                    </div>

                    <div className="col-span-12">
                      <h6>{t('common.urgent')}</h6>
                      <div className="flex items-center gap-1 mt-1">
                        {YES_NO_OPTIONS.map((option) => (
                          <Chip
                            key={option.text}
                            theme="blue-800"
                            variant="outline"
                            active={data.urgent === option.value}
                            clickable={!data.customer?.vip}
                            disabled={data.customer?.vip}
                            onClick={() => onFieldChange('urgent', option.value)}
                          >
                            {option.text}
                          </Chip>
                        ))}
                      </div>
                    </div>

                    <div className="col-span-12">
                      <h6>{t('common.payment')}</h6>
                      <div className="flex items-center gap-1 mt-1">
                        {PAYMENT_STATUSES.map((option) => {
                          if (option.value === PAYMENT_STATUS.CASH_ON_DELIVERY && data?.deliveryMethod !== DELIVERY_METHOD.SHIPPING) {
                            return null;
                          }
                          return (
                            <Chip
                              key={option.value}
                              theme="blue-800"
                              variant="outline"
                              active={data.paymentStatus === option.value}
                              clickable
                              onClick={() => onFieldChange('paymentStatus', option.value)}
                            >
                              {option.text}
                            </Chip>
                          );
                        })}
                      </div>
                    </div>

                    <div className="col-span-12">
                      <h6>{t('common.loosePiece')} / {t('common.set')}</h6>
                      <div className="flex items-center flex-wrap gap-1 mt-1">
                        {ORDER_TYPES.map((option) => (
                          <Chip
                            key={option.value}
                            theme="blue-800"
                            variant="outline"
                            active={data.type === option.value}
                            clickable
                            onClick={() => onFieldChange('type', option.value)}
                          >
                            {option.text}
                          </Chip>
                        ))}
                      </div>
                    </div>

                    <div className="col-span-12">
                      <h6>{t('common.guarantee')}</h6>
                      <div className="flex items-center gap-1 mt-1">
                        {YES_NO_OPTIONS.map((option) => (
                          <Chip
                            key={option.text}
                            theme="blue-800"
                            variant="outline"
                            active={data.guarantee === option.value}
                            clickable
                            onClick={() => onFieldChange('guarantee', option.value)}
                          >
                            {option.text}
                          </Chip>
                        ))}
                      </div>
                    </div>

                    <div className="col-span-12">
                      <h6>{t('common.remark')}</h6>
                      <div className="form-field mt-1">
                        <TextField
                          value={data.comment || ''}
                          InputProps={{
                            inputComponent: TextareaAutosize,
                          }}
                          error={(data.comment || '').length > 500}
                          helperText={`${(data.comment || '').length} / 500`}
                          onChange={(e) => onFieldChange('comment', e.target.value)}
                          onBlur={() => onFieldChange('deliveryMethod', data?.deliveryMethod)}
                        />
                      </div>
                    </div>

                    <div className="col-span-12">
                      <h6>{t('common.internalRemark')}</h6>
                      <div className="form-field mt-1">
                        <TextField
                          value={data.internalComment || ''}
                          InputProps={{
                            inputComponent: TextareaAutosize,
                          }}
                          onChange={(e) => {
                            onFieldChange('internalComment', e.target.value)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-span-12 md:col-span-5 flex flex-col gap-y-4">
                  {/** CLIENT */}
                  <div className="card grow">
                    <h2>{t('common.customer')}</h2>

                    <div className="mt-6">
                      <h6>{t('common.name')}</h6>
                      <div className="flex items-center mt-1">
                        {data.customer?.country && (
                          <img className="w-6 mr-2" src={`https://flagicons.lipis.dev/flags/4x3/${data.customer.country.toLowerCase()}.svg`} alt={data.customer.country} />
                        )}
                        {data.customer?.fullName}
                      </div>
                    </div>

                    <div className="mt-6">
                      <h6>{t('common.company')}</h6>
                      <div className="mt-1">{data.customer?.companyName}</div>
                    </div>

                    <div className="mt-6">
                      <h6>{t('common.telephone')}</h6>
                      <div className="mt-1">{data.customer?.phone}</div>
                    </div>

                    <div className="mt-6">
                      <h6>{t('common.email')}</h6>
                      <div className="mt-1">
                        <a className="text-blue font-semibold" href={`mailto:${data.customer?.email}`} target="_blank" rel="noreferrer">
                          {data.customer?.email}
                        </a>
                      </div>
                    </div>

                    <div className="mt-6">
                      <h6>{t('common.address')}</h6>
                      <div className="mt-1">{data.customer?.address}</div>
                    </div>

                    {data.customer?.vip && (
                      <div className="mt-6">
                        <Chip theme="danger" size="sm">{t('common.vip')}</Chip>
                      </div>
                    )}

                    <div className="mt-6">
                      <Link className="flex items-center text-blue font-semibold" to={`/customers/${data.customer?.id}`}>
                        <span className="mr-3">{t('common.viewFullProfile')}</span>
                        <ArrowRightIcon size={20} />
                      </Link>
                    </div>
                  </div>
                  {/** IMAGE UPLOAD */}
                  <div className="card">
                    <h2>{t("common.image")}</h2>
                    <Uploads className="mt-6" repair={data} orderImages={orderImages} onSelect={onOrderImageSelect} onAdd={onOrderImageAdded} />
                  </div>
                </div>

                <div className="card col-span-12">
                  <div className="flex items-center justify-between">
                    <h2>{t('repairs.repairDetails')}</h2>
                    <div className="flex items-center justify-between gap-2">
                      <DymoPrint allProducts={allProducts} repair={data} />
                      <button className="btn btn-blue" disabled={!data.deliveryMethod} onClick={() => setShowPanel("addProducts")}>
                        {t('repairs.addProducts')}
                      </button>
                      </div>
                  </div>
                  <RepairDetailTable className="mt-6" repair={data} onSelect={onOrderProductSelect} />
                </div>

                <div className="card col-span-12">
                  <h2>{t('common.history')}</h2>
                  <RepairHistoryTable className="mt-6" tableData={orderLogs} />
                </div>

              </div>
            </>
          )
        )}
      </FullLayout>

      {data?.deliveryMethod && (
        <AddProductsPanel
          opened={showPanel==="addProducts"}
          order={data}
          onSubmit={onAddProducts}
          onClose={() => setShowPanel("none")}
        />
      )}

      {data?.customer && (
        <ActionsPanel
          repair={data}
          onClose={() => {setShowPanel("none")}}
          isOpen={showPanel==="actions"}
        />
      )}

      {(data && selectedOrderImage) && (
        <ImageActionsPanel
          repair={data}
          orderImage={selectedOrderImage}
          onClose={onCloseImageActions}
          isOpen={showPanel==="imageActions"}
        />
      )}

      {(data && selectedOrderProduct && showPanel==="changeBin") && (
        <ChangeBin
          repair={data}
          orderProduct={selectedOrderProduct}
          onSubmit={onChangeBin}
          onClose={() => {setSelectedOrderProduct(null); setShowPanel("none")}}
          isOpen={showPanel==="changeBin"}
        />
      )}

      {(data && selectedOrderProduct && showPanel==="editOrderProductComment") && (
        <EditComment
          repair={data}
          orderProduct={selectedOrderProduct}
          onSubmit={onChangeOrderProductComment}
          onClose={() => {setSelectedOrderProduct(null); setShowPanel("none")}}
          isOpen={showPanel==="editOrderProductComment"}
        />
      )}

      {(data && selectedOrderProduct && showPanel==="removeOrderProduct") && (
        <RemoveProduct
          repair={data}
          orderProduct={selectedOrderProduct}
          onRemove={onRemoveOrderProduct}
          onClose={() => {setSelectedOrderProduct(null); setShowPanel("none")}}
          isOpen={showPanel==="removeOrderProduct"}
        />
      )}
    </>
  );
};

export default RepairDetail;
