type urlString = string;

export class OrderImageModel {
  id: string;
  url: urlString;
  thumbnailUrl: urlString;
  orderId: string;

  constructor(init: Partial<OrderImageModel>, orderId: string) {
    this.orderId = orderId;
    this.id = init?.id || "";

    this.url = init?.url || "";
    this.thumbnailUrl = init?.thumbnailUrl || "";
  }
}
