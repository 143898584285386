import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomDrawer } from "@/components";
import { CustomerModel } from "@/resources/models";
import { CustomerService, ToastService } from "@/services";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { useAppSelector } from "@/redux/hooks";

export interface IActionsPanelProps {
  customer: CustomerModel;
  onClose(): void;
  isOpen: boolean;
}

const ActionsPanel: FC<IActionsPanelProps> = ({
  customer,
  isOpen,
  onClose,
}) => {
  const navigate = useNavigate();

  const [showConfirmation, setShowConfirmation] = useState(true);
  const { user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    setShowConfirmation(false);
  }, [isOpen]);

  const { t } = useTranslation();

  const removeCustomer = useCallback(async () => {
    try {
      await CustomerService.remove(customer.id);
      ToastService.success(t("customers.customerDeletedMessage"));
      navigate("/customers");
    } catch (err: unknown) {
      if (err instanceof AxiosError || err instanceof Error) {
        ToastService.showHttpError(err, t("common.operationFailedMessage"));
      }
    }
  }, [customer, navigate, t]);

  return (
    <CustomDrawer
      isOpen={isOpen}
      title={t("common.customer") || ""}
      position="end"
      contentClass="p-8"
      onClose={onClose}
    >
      <div className="flex flex-col items-start gap-6">
        {user?.isAdmin() && (
          <div className="flex flex-col items-start gap-6">
            {
              <button
                type="submit"
                className="btn btn-blue"
                onClick={() => {
                  setShowConfirmation(true);
                }}
                disabled={showConfirmation}
              >
                {t("customers.deleteCustomer")}
              </button>
            }
            {showConfirmation && (
              <div>
                <p className="mt-4 mb-4">
                  {t("customers.deletionConfirmMessage")}
                </p>
                <button
                  type="submit"
                  className="btn bg-danger-light text-danger-dark"
                  onClick={() => {
                    removeCustomer();
                  }}
                >
                  {t("customers.deleteCustomer")}
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </CustomDrawer>
  );
};

export default ActionsPanel;
