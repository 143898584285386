import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Chip} from "@/components";
import {ArrowLeftIcon, MoreVertIcon} from "@/icons";
import FullLayout from "@/layouts/FullLayout";
import OrdersTable from "@/pages/Customers/Detail/OrdersTable";
import EditCustomerPanel from "@/pages/Customers/Detail/EditCustomerPanel";
import {CUSTOMER_TYPE} from "@/resources/enums";
import {CustomerModel} from "@/resources/models";
import {CustomerService, ToastService} from "@/services";
import {formatDate, getCountryName, getCustomerTypeText, getLanguageNameFromCountryCode} from "@/utils/helpers";
import ActionsPanel from "./ActionsPanel";

const CustomerDetail = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();
  const [customer, setCustomer] = useState<CustomerModel>();
  const [language, setLanguage] = useState('');
  const [editing, setEditing] = useState(false);
  const [showActions, setShowActions] = useState(false);

  useEffect(() => {
    if (!id) {
      return;
    }
    CustomerService.find(id).then((customer) => {
      setCustomer(customer);
    }).catch((err) => {
      ToastService.showHttpError(err, 'Loading customer failed');
    });
  }, [id]);

  useEffect(() => {
    getLanguageNameFromCountryCode(customer?.country).then((lang) => {
      setLanguage(lang);
    });
  }, [customer?.country]);

  const onClose = () => {
    navigate(-1);
  };

  const onCloseEditPanel = (updatedCustomer?: CustomerModel) => {
    if (updatedCustomer) {
      setCustomer(updatedCustomer);
    }
    setEditing(false);
  };

  return (
    <>
      <FullLayout
        header={(
          <>
            <button className="icon-btn" onClick={onClose}>
              <ArrowLeftIcon />
            </button>
            <h2 className="ml-4">{t('common.customer')}</h2>
            <span className="text-blue-400 ml-4">{id || ''}</span>
            <button className="icon-btn ml-auto" onClick={() => setShowActions(true)}>
              <MoreVertIcon />
            </button>
            <button className="btn btn-blue ml-4" onClick={() => setEditing(true)}>
              {t('common.edit')}
            </button>
          </>
        )}
      >
        {customer && (
          <>
            <div className="card">
              <h2>{t('common.overview')}</h2>

              <div className="grid sm:grid-cols-2 gap-6 mt-6">
                <div>
                  <h6>{t('common.customerNumber')}</h6>
                  <div className="mt-1">{customer.customerNumber}</div>
                </div>

                <div>
                  <h6>{t('common.name')}</h6>
                  <div className="mt-1">{customer.fullName}</div>
                </div>

                <div>
                  <h6>{t('common.type')}</h6>
                  <div className="mt-1">{getCustomerTypeText(customer.type)}</div>
                </div>

                <div>
                  <h6>{t('common.email')}</h6>
                  <div className="mt-1">
                    <a className="text-blue font-semibold" href={`mailto:${customer.email}`} target="_blank" rel="noreferrer">
                      {customer.email}
                    </a>
                  </div>
                </div>

                <div>
                  <h6>{t('common.telephone')}</h6>
                  <div className="mt-1">{customer.phone}</div>
                </div>

                {customer.type === CUSTOMER_TYPE.COMPANY && (
                  <>
                    <div>
                      <h6>{t('common.company')}</h6>
                      <div className="mt-1">{customer.companyName}</div>
                    </div>

                    <div>
                      <h6>{t('common.vatNumber')}</h6>
                      <div className="mt-1">{customer.vatNumber}</div>
                    </div>
                  </>
                )}

                <div>
                  <h6>{t('common.address')}</h6>
                  <div className="mt-1">{customer.address}, {getCountryName(customer.country)}</div>
                </div>

                <div>
                  <h6>{t('common.language')}</h6>
                  <div className="mt-1">{language}</div>
                </div>

                <div>
                  <h6>{t('common.registerDate')}</h6>
                  <div className="lowercase mt-1">{formatDate(customer.createdAt, 'full')}</div>
                </div>

                {customer.vip && (
                  <div>
                    <Chip theme="danger" size="sm">{t('common.vip')}</Chip>
                  </div>
                )}
              </div>
            </div>

            <div className="card mt-6">
              <OrdersTable customer={customer} />
            </div>
          </>
        )}
      </FullLayout>

      {customer && (
        <EditCustomerPanel
          opened={editing}
          customer={customer}
          onClose={onCloseEditPanel}
        />
      )}

      {customer && (
        <ActionsPanel
          customer={customer}
          onClose={() => {setShowActions(false)}}
          isOpen={showActions}
        />
      )}
    </>
  );
};
export default CustomerDetail;
