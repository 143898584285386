import { MoreVertIcon } from "@/icons";
import { OrderImageModel } from "@/resources/models/order-image.model";
import React from "react";

interface ThumbnailProps {
  orderImage: OrderImageModel;
  onClick: () => void;
}

const Thumbnail: React.FC<ThumbnailProps> = ({ orderImage, onClick }) => {
  return (
    <div className="relative">
      <a href={orderImage.url} target="_blank" rel="noreferrer">
        <img
          src={orderImage.thumbnailUrl}
          alt={`${orderImage.id}`}
          className="w-full h-full object-cover rounded-sm"
        />
      </a>
      <button onClick={onClick} className="absolute bottom-2 right-2 icon-btn">
        <MoreVertIcon />
      </button>
    </div>
  );
};

export default Thumbnail;
