export const trimObject = (object: any, removals = [undefined, null, '']) => {
  if (typeof object !== 'object' || Array.isArray(object)) {
    return object;
  }

  const result: any = {};
  Object.entries(object).forEach(([key, value]: [string, any]) => {
    if (!removals.includes(value)) {
      result[key] = value;
    }
  });
  return result;
};

export function getUniqueItems<T extends Record<string, any>, K extends keyof T>(
  array: T[],
  property: K
): Map<string, NonNullable<T[K]>> {
  const uniqueMap = new Map<string, NonNullable<T[K]>>();

  for (const item of array) {
    const propValue = item[property];
    if (propValue && typeof propValue === 'object' && 'id' in propValue) {
      uniqueMap.set(String(propValue.id), propValue as NonNullable<T[K]>);
    }
  }

  return uniqueMap;
}

