import {FC, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {DataTable, IDataTableColumn, ISortModel} from "@/components";
import {REPAIR_STATUS} from "@/resources/enums";
import {OrderProductModel, RepairModel} from "@/resources/models";
import {formatCurrency, getRepairStatusText} from "@/utils/helpers";
import { OrderProductMenu, MenuAction } from "../OrderProductMenu";

interface IFilter {
  sort: ISortModel[];
}

export interface IRepairDetailTableProps {
  className?: string;
  repair: RepairModel;
  onSelect: (orderProduct: OrderProductModel, menuAction: MenuAction) => void;
}

const RepairDetailTable: FC<IRepairDetailTableProps> = ({
  className,
  repair,
  onSelect,
}) => {
  const { t } = useTranslation();

  const [filter, setFilter] = useState<IFilter>({
    sort: [],
  });

  const sortable = false;
  const tableColumns: IDataTableColumn[] = [
    {
      title: '',
      field: 'binId',
      maxWidth: '3rem',
      getText(_data: OrderProductModel, index) {
        return `${index+1}`;
      },
    },
    {
      title: t('common.bin') || 'Bin',
      field: 'binId',
      sortable,
      getText(data: OrderProductModel) {
        return data.bin?.name || '';
      },
    },
    {
      title: t('common.image') || 'Image',
      field: 'image',
      sortable,
      render(data: OrderProductModel) {
        if (!data.image) {
          return null;
        }
        return (
          <img className="w-24" src={data.image} alt="" />
        );
      },
    },
    {
      title: t("common.components") || "Components",
      field: "name",
      sortable,
      render(data: OrderProductModel) {
        return (
          <div className="w-full">
            <div>{data.name}</div>
            {data.comment && (
              <div className="flex gap-2 max-w-xl">
                <div className="font-semibold">
                  <Trans i18nKey="common.remark" />
                </div>
                <div className="text-ellipsis overflow-hidden">
                {data.comment}
                </div>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: t('common.repairNumber') || 'Repair number',
      field: 'id',
      sortable,
      render(data: OrderProductModel) {
        return (
          <div id={OrderProductModel.asHtmlId(data.id)}>
            {data.id}
          </div>
        );
      },
    },
    {
      title: t('common.status') || 'Status',
      field: 'status',
      sortable,
      render(_data: OrderProductModel) {
        return getRepairStatusText(REPAIR_STATUS.READY);
      },
    },
    {
      title: `${t('common.price')} / ${t('common.unit').toLowerCase()}`,
      field: 'price',
      align: 'right',
      sortable,
      render(data: OrderProductModel) {
        return (
          <div>
            {formatCurrency(data.price)} <span className="text-blue-400">€</span>
          </div>
        );
      },
    },
    {
      field: '',
      maxWidth: '5rem',
      align: 'right',
      cellClass: 'items-center whitespace-nowrap !py-3 gap-2',
      render(data: OrderProductModel) {
        return (
          <OrderProductMenu onChange={(menuAction:MenuAction) => {onSelect(data, menuAction) }} />
        )
      }
    },
  ];

  const renderTableFooter = (data: OrderProductModel[]) => {
    const subTotal = data.reduce((sum, item) => sum + item.price * item.count, 0);
    const taxPercent = 21;
    const tax = subTotal * taxPercent / 100;
    const total = subTotal + tax;

    return (
      <>
        <div className="tf col-span-5" />
        <div className="tf font-semibold pt-5">{t('common.subTotal')}:</div>
        <div className="tf justify-end pt-5">
          {formatCurrency(subTotal)} <span className="text-blue-400 ml-1">€</span>
        </div>
        <div className="tf" />

        <div className="tf col-span-5 !border-0" />
        <div className="tf font-semibold !border-0 pt-2">{t('common.tax')} ({taxPercent}%):</div>
        <div className="tf justify-end !border-0 pt-2">
          {formatCurrency(tax)} <span className="text-blue-400 ml-1">€</span>
        </div>

        <div className="tf col-span-5 !border-0" />
        <div className="tf font-semibold !border-0 pt-2">{t('common.total')}:</div>
        <div className="tf justify-end !border-0 pt-2">
          <span className="font-semibold">{formatCurrency(total)}</span>
          <span className="text-blue-400 ml-1">€</span>
        </div>
      </>
    );
  };

  const onFilterChange = (field: string, value: any) => {
    setFilter((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <DataTable
      className={className}
      columns={tableColumns}
      data={repair.products || []}
      footer={renderTableFooter}
      cellClass="items-center !px-2 !py-3"
      headerCellClass="!px-2 !py-3"
      serverSide={false}
      sortModel={filter.sort}
      onSort={(model) => onFilterChange('sort', model)}
    />
  );
};
export default RepairDetailTable;
