import { DELIVERY_METHOD, DELIVERY_METHOD_VALUES } from "@/resources/enums";
import { BinContents } from "@/resources/interfaces/bins";

export const BIN_CONTENTS: Array<BinContents> = [
  {
    content: 22,
    image: "/assets/images/bin-22.jpg",
    deliveryMethods: [DELIVERY_METHOD.PICKUP, DELIVERY_METHOD.SHIPPING],
  },
  {
    content: 26,
    image: "/assets/images/bin-26.jpg",
    deliveryMethods: [DELIVERY_METHOD.PICKUP, DELIVERY_METHOD.SHIPPING],
  },
  { content: 40, image: "/assets/images/bin-40.jpg", deliveryMethods: DELIVERY_METHOD_VALUES },
  {
    content: 51,
    image: "/assets/images/bin-51.jpg",
    deliveryMethods: [DELIVERY_METHOD.PICKUP, DELIVERY_METHOD.SHIPPING],
  },
  {
    content: 61,
    image: "/assets/images/bin-61.jpg",
    deliveryMethods: [DELIVERY_METHOD.PICKUP, DELIVERY_METHOD.SHIPPING],
  },
];
