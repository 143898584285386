import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Chip, ChipTheme, DataTable, IDataTableColumn, IGroupModel, IPaginationModel, ISortModel } from "@/components";
import { TimeErrorIcon } from "@/icons";
import FullLayout from "@/layouts/FullLayout";
import Filter from "@/pages/Repairs/Home/Filter";
import { RepairFilter } from "@/pages/Repairs/Home/FilterMenu";
import {useAppDispatch, useAppSelector} from "@/redux/hooks";
import { setRepairStatusFilter } from "@/redux/reducers/repair.reducer";
import { DELIVERY_METHOD, DELIVERY_METHODS, REPAIR_STATUS } from "@/resources/enums";
import { RepairModel } from "@/resources/models";
import { RepairService } from "@/services";
import { formatDate, getDeliveryMethodText, getRepairStatusText, getSortQueryString } from "@/utils/helpers";

export interface IFilter {
  search: string;
  deliveryMethod: DELIVERY_METHOD[];
  status: REPAIR_STATUS[];
  filter?: RepairFilter;
  sort: ISortModel[];
  group?: IGroupModel;
  pagination: IPaginationModel;
}

const Repairs = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { selectedStatus } = useAppSelector((state) => state.repair);

  const [filter, setFilter] = useState<IFilter>({
    search: '',
    deliveryMethod: [],
    status: selectedStatus ? [selectedStatus] : [],
    sort: [{ field: 'createdAt', dir: 'DESC' }],
    pagination: {
      page: 0,
      perPage: 20,
    },
  });
  const [totalCount, setTotalCount] = useState(0);
  const [repairs, setRepairs] = useState<RepairModel[]>([]);

  const tableColumns: IDataTableColumn[] = [
    {
      title: t('repairs.tableColumns.customer') || 'Customer',
      field: 'customer.firstName',
      sortable: true,
      render(data: RepairModel) {
        return (
          <>
            {data.customer?.country && (
              <img className="w-6 mr-2" src={`https://flagicons.lipis.dev/flags/4x3/${data.customer.country.toLowerCase()}.svg`} alt={data.customer.country} />
            )}
            <Link className="hover:underline" to={`/repairs/${data.id}`}>
              {data.customer?.fullName}
            </Link>
          </>
        );
      },
    },
    { 
      title: t('repairs.tableColumns.repairNumber') || 'Repair number', field: 'id',
      sortable: true,
      render(data: RepairModel) {
        return (
          <Link className="hover:underline" to={`/repairs/${data.id}`}>
            {data.id}
          </Link>
        );
      },
    },
    {
      title: t('repairs.tableColumns.deliveryMethod') || 'Delivery method',
      field: 'deliveryMethod',
      sortable: true,
      cellClass: '!py-3',
      render(data) {
        const deliveryMethod = DELIVERY_METHODS.find((item) => item.value === data.deliveryMethod);
        return (
          <div className="flex items-center flex-wrap gap-1">
            {data.deliveryMethod && (
              <Chip theme={(deliveryMethod?.color || 'secondary') as ChipTheme} size="sm">{deliveryMethod?.text || data.deliveryMethod}</Chip>
            )}
            {data.urgent && (
              <Chip theme="danger" size="sm">{t('common.urgent')}</Chip>
            )}
          </div>
        );
      },
    },
    {
      title: t('repairs.tableColumns.status') || 'Status',
      field: 'status',
      sortable: true,
      getText(data: RepairModel) {
        return getRepairStatusText(data.status);
      },
    },
    {
      title: t('repairs.tableColumns.receiptDate') || 'Receipt date',
      field: 'createdAt',
      cellClass: 'text-blue-400',
      sortable: true,
      render(data: RepairModel) {
        return (
          <div className="flex-center">
            {formatDate(data.createdAt, 'full').toLowerCase()}
            {filter.filter === 'overdue' && (
              <span className="flex-center text-danger ml-2">
                <TimeErrorIcon size={20} /> {data.overdueDays}
              </span>
            )}
          </div>
        );
      },
    },
  ];

  const loadTableData = useCallback(() => {
    RepairService.search({
      search: filter.search,
      deliveryMethod: filter.deliveryMethod.length ? filter.deliveryMethod : undefined,
      status: filter.status.length ? filter.status : undefined,
      filter: filter.filter,
      skip: filter.pagination.page * filter.pagination.perPage,
      limit: filter.pagination.perPage,
      sort: getSortQueryString(filter.sort),
    }).then((res) => {
      setRepairs(res.data);
      setTotalCount(res.totalCount);
    });
  }, [filter]);

  useEffect(() => {
    loadTableData();
  }, [loadTableData]);

  useEffect(() => {
    dispatch(setRepairStatusFilter(filter.status[0]));
  }, [dispatch, filter]);

  const onFilterChange = useCallback((field: string, value: any) => {
    setFilter((prev) => ({
      ...prev,
      [field]: value,
      ...(field === 'search' && ({
        pagination: {
          ...prev.pagination,
          page: 0,
        },
      })),
    }));
  }, []);

  const onUpdateFilter = (updates: Partial<IFilter>) => {
    setFilter((prev) => ({
      ...prev,
      ...updates,
    }));
  };

  const onRemoveFilter = (field: 'status' | 'deliveryMethod', value: string) => {
    const newFilter = (filter[field] as any[]).filter((item) => item !== value);
    setFilter({
      ...filter,
      [field]: newFilter,
    });
  };

  const hasFilter = filter.status.length > 0 || filter.deliveryMethod.length > 0 || filter.filter;

  return (
    <FullLayout onSearchChange={onFilterChange}>
      <div className="flex items-center justify-between flex-wrap gap-2">
        <h1 className="mr-8">{t('repairs.pageTitle')}</h1>
        <Filter filter={filter} onChange={onUpdateFilter} />
      </div>

      {hasFilter && (
        <div className="flex items-center gap-2 mt-4">
          {filter.deliveryMethod.map((item) => (
            <Chip
              key={item}
              theme="blue-800"
              onRemove={() => onRemoveFilter('deliveryMethod', item)}
            >
              {getDeliveryMethodText(item)}
            </Chip>
          ))}

          {filter.status.map((item) => (
            <Chip
              key={item}
              theme="blue-800"
              onRemove={() => onRemoveFilter('status', item)}
            >
              {getRepairStatusText(item)}
            </Chip>
          ))}

          {filter.filter && (
            <Chip
              theme="blue-800"
              onRemove={() => onFilterChange('filter', undefined)}
            >
              {t(`common.${filter.filter}`)}
            </Chip>
          )}
        </div>
      )}

      <DataTable
        className="mt-4"
        theme="card-row"
        columns={tableColumns}
        data={repairs}
        serverSide
        sortModel={filter.sort}
        groupModel={filter.group}
        pagination="auto"
        paginationModel={filter.pagination}
        totalCount={totalCount}
        onSort={(model) => onFilterChange('sort', model)}
        onPaginate={(model) => onFilterChange('pagination', model)}
      />
    </FullLayout>
  );
};
export default Repairs;
