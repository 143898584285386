import { FC, ReactNode, useEffect, useState } from "react";
import classnames from "classnames";
import { CloseIcon } from "@/icons";

export type DrawerSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';

interface DrawerProps {
  isOpen: boolean;
  containerClass?: string;
  contentClass?: string;
  lazyLoad?: boolean;
  position?: 'start' | 'end';
  size?: DrawerSize;
  title?: string;
  headerActions?: ReactNode;
  onClose?: () => void;
  children: ReactNode;
}

export const CustomDrawer: FC<DrawerProps> = ({
  isOpen,
  containerClass = "",
  contentClass,
  lazyLoad,
  position = "start",
  size = "md",
  title = "",
  headerActions,
  onClose,
  children,
}) => {
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  const handleTransitionEnd = () => {
    setIsTransitioning(false);
  };

  const handleOverlayClick = () => {
    setIsTransitioning(true);
    if (onClose) {
      onClose();
    }
  };

  const className = classnames(
    'fixed inset-y-0 z-40 w-full h-screen transform bg-white overflow-y-auto',
    'flex flex-col',
    size === 'sm' && 'max-w-92',
    size === 'md' && 'max-w-120',
    size === 'lg' && 'max-w-160',
    size === 'xl' && 'max-w-240',
    position === 'end' ? 'right-0' : 'left-0',
    isOpen && 'ease-out duration-300 translate-x-0',
    !isOpen && [
      'ease-in duration-200',
      (position === 'end' ? 'translate-x-full' : 'translate-x-[-100%]'),
      (isTransitioning ? 'opacity-0' : 'opacity-100'),
    ],
  );

  return (
    <>
      <div
        className={classnames(
          'fixed inset-0 z-30',
          isOpen ? 'block' : 'hidden',
          containerClass,
        )}
        onClick={handleOverlayClick}
        onTransitionEnd={handleTransitionEnd}
      >
        <div className="absolute inset-0 bg-blue-400 opacity-32" />
      </div>
      <div className={className}>
        <div
          className={classnames(
            'relative w-full flex items-center justify-between px-8 py-4 border-b border-blue-200',
            position === 'end' && 'flex-row-reverse'
          )}
        >
          <button
            type="button"
            className="icon-btn"
            onClick={handleOverlayClick}
          >
            <span className="sr-only">Close panel</span>
            <CloseIcon />
          </button>
          {headerActions && (
            <div className="flex mx-2">
              {headerActions}
            </div>
          )}
          {title && (
            <div className={classnames('font-bold text-2xl', position === 'start' ? 'ml-auto' : 'mr-auto')}>{title}</div>
          )}
        </div>
        <div className={classnames('h-0 grow overflow-auto', contentClass)}>
          {(isOpen || !lazyLoad) && children}
        </div>
      </div>
    </>
  );
};
