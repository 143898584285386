import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BarcodeListener from "./BarcodeListener";
import { BarcodeDataKey } from "./types";
import BarcodeIndicator from "./BarcodeIndicator";

interface BarcodeNavigatorProps {
  showIndicator?: boolean;
}

const BarcodeNavigator: React.FC<BarcodeNavigatorProps> = ({
  showIndicator = false,
}) => {
  const navigate = useNavigate();
  const [focusedElement, setFocusedElement] = useState<string | null>(null);
  const [barcodeString, setBarcodeString] = useState<string>("");

  // Function to handle barcode scans and route accordingly
  const handleBarcodeScan = (barcodeData: Record<BarcodeDataKey, string>) => {
    // Get the currently focused element
    const activeElement = document.activeElement;
    setFocusedElement(
      activeElement ? activeElement.tagName.toLowerCase() : "none"
    );
    //
    setBarcodeString(JSON.stringify(barcodeData));

    try {
      if (barcodeData?.customer) {
        navigate(`/customers/${barcodeData.customer}`);
      } else if (barcodeData?.order && barcodeData?.orderProduct) {
        navigate(
          `/repairs/${barcodeData.order}?order_product=${barcodeData.orderProduct}`
        );
      } else if (barcodeData?.order) {
        navigate(`/repairs/${barcodeData.order}`);
      }
    } catch (error) {
      console.error("Invalid barcode data:", error);
    }
  };

  return (
    <>
      <BarcodeListener onChange={handleBarcodeScan} />
      {showIndicator && (
        <BarcodeIndicator
          barcodeString={barcodeString}
          focusedElement={focusedElement}
        />
      )}
    </>
  );
};

export default BarcodeNavigator;
