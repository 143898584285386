import { useDymoCheckService, useDymoFetchPrinters } from "@/hooks/dymo";
import { AlertIcon } from "@/icons/Alert";
import { BinModel, OrderProductModel, ProductModel, RepairModel } from "@/resources/models";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePrintLabelsCallback } from "./PrintLabelsHook";
import { InputBase, MenuItem, Select } from "@mui/material";
import {IDymoPrinters} from "@/resources/interfaces/dymo.interface";

export interface IDymoPrintProps {
  className?: string;
  repair: RepairModel | undefined;
  allProducts: Partial<ProductModel>[];
}

function filterUniqueBins(orderProducts?: OrderProductModel[]): BinModel[] {
  const uniqueBinMap = new Map<number, BinModel>();
  orderProducts &&
    orderProducts.forEach((orderProduct) => {
      if (
        orderProduct.bin &&
        orderProduct.bin instanceof BinModel &&
        !uniqueBinMap.has(orderProduct.bin.id)
      ) {
        uniqueBinMap.set(orderProduct.bin.id, orderProduct.bin);
      }
    });
  return Array.from(uniqueBinMap.values());
}

export const DymoPrint: FC<IDymoPrintProps> = ({ repair, className, allProducts }) => {
  const statusDymoService = useDymoCheckService();
  const { printers }: IDymoPrinters = useDymoFetchPrinters(statusDymoService);
  const { t } = useTranslation();
  const bins = filterUniqueBins(repair?.products);
  const [selectedBin, setSelectedBin] = useState<number | undefined>(
    bins.length > 0 ? bins[0].id : undefined
  );
  const onPrint = usePrintLabelsCallback(repair, printers, selectedBin, allProducts);

  if (statusDymoService === "error") {
    return (
      <span className="flex-center text-danger mr-2">
        <AlertIcon size={16} className="mr-2" />
        {t("dymowebservice.error")}
      </span>
    );
  }
  return (
    <>
      {bins.length > 0 && (
        <Select
          className=""
          value={selectedBin}
          onChange={(e) => setSelectedBin(e.target.value as number)}
          input={<InputBase />}
        >
          {bins.map((option) => (
            <MenuItem key={option.id} className="p-0" value={option.id}>
              <div className="flex items-center justify-between grow">
                <span className="text-blue-900">{option.name}</span>
              </div>
            </MenuItem>
          ))}
        </Select>
      )}
      <button
        className={`btn bg-blue-200 ${className}`}
        disabled={!repair || repair.products.length === 0}
        onClick={onPrint}
      >
        {t("repairs.printProductLabels")}
      </button>
    </>
  );
};

export default DymoPrint;
