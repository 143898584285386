import { FC, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Chip, CustomDrawer } from "@/components";
import { BinModel, OrderProductModel, RepairModel } from "@/resources/models";
import { OrderProductCard } from "./OrderProductCard";
import { BinInfo, useAvailableBins } from "@/hooks/bins/useAvailableBins";
import { DELIVERY_METHOD } from "@/resources/enums";
import { BinCard } from "../BinCard";
import { getUniqueItems } from "@/utils/helpers";
import { BIN_CONTENTS } from "@/constants";
import { sortBy } from "lodash";

export interface IActionsPanelProps {
  repair: RepairModel;
  orderProduct: OrderProductModel | null;
  onClose: (reloadOrderProducts: boolean) => void;
  onSubmit(repair: RepairModel, orderProduct: OrderProductModel, binId: number): void;
  isOpen: boolean;
}

type BinSourceValue = "selectOrderBin" | "selectNewBin";

export const ChangeBin: FC<IActionsPanelProps> = ({ repair, orderProduct, isOpen, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const [bin, setBin] = useState<BinModel | undefined>();
  const [binSource, setBinSource] = useState<BinSourceValue>("selectOrderBin");

  const binSourceOptions: Array<{ text: string; value: BinSourceValue }> = [
    { text: "repairs.selectOrderBin", value: "selectOrderBin" },
    { text: "repairs.bookInBin", value: "selectNewBin" },
  ];

  const availableBins = useAvailableBins(isOpen, repair.deliveryMethod as DELIVERY_METHOD, t);
  const currentOrderBins: Array<BinInfo> = sortBy(
    Array.from(getUniqueItems(repair.products, "bin").values()),
    "content"
  )
    // Ignore the current OrderProduct Bin
    .filter((binModel: BinModel) => binModel.id !== orderProduct?.bin?.id)
    .map(
      (binModel: BinModel, idx): BinInfo => ({
        content: binModel.content,
        image: BinModel.getContentsImage(binModel, BIN_CONTENTS),
        bin: binModel,
        key: `bin-info-${idx}`,
      })
    );

  const onSelectBin = (bin?: BinModel) => {
    if (bin) {
      setBin(bin);
    }
  };

  useEffect(() => {
    setBin(undefined);
  }, [binSource]);

  const onChangeBin = () => {
    if (bin && orderProduct) {
      onSubmit(repair, orderProduct, bin.id);
    }
  };
  const showProductCard = false;

  return (
    <CustomDrawer
      isOpen={isOpen}
      title={t("repairs.changeBin") || ""}
      position="end"
      contentClass="p-8"
      size="xl"
      headerActions={
        <button className="btn btn-blue" disabled={!bin} onClick={onChangeBin}>
          {t("repairs.changeBin")}
        </button>
      }
      onClose={() => {
        onClose(false);
      }}
    >
      <div className="flex flex-col items-start gap-6">
        {/** MODEL */}
        {showProductCard && <OrderProductCard orderProduct={orderProduct} />}
        {orderProduct && (
          <div>
            <h6 className="font-bold">
              <Trans i18nKey="repairs.selectBin" />
            </h6>
            {/** TOGGLE */}
            <div className="flex flex-wrap gap-2 mt-2">
              {binSourceOptions.map((option) => (
                <Chip
                  key={option.value}
                  theme="blue-800"
                  variant="outline"
                  active={binSource === option.value}
                  clickable
                  onClick={() => setBinSource(option.value)}
                >
                  {t(option.text)}
                </Chip>
              ))}
            </div>

            <div className="flex flex-wrap items-stretch gap-6 mt-4">
              {/** NEW BIN */}
              {binSource === "selectNewBin" &&
                availableBins.map((item) => (
                  <BinCard key={item.key} activeBin={bin} binInfo={item} onSelect={onSelectBin} />
                ))}

              {/** ORDER BIN */}
              {binSource === "selectOrderBin" &&
                currentOrderBins.map((item) => (
                  <BinCard key={item.key} activeBin={bin} binInfo={item} onSelect={onSelectBin} />
                ))}
            </div>
          </div>
        )}
      </div>
    </CustomDrawer>
  );
};
