import React, { useEffect, useRef, useState } from "react";

interface BarcodeIndicatorProps {
  barcodeString: string;
  focusedElement: string | null;
}

const BarcodeIndicator: React.FC<BarcodeIndicatorProps> = ({
  barcodeString,
  focusedElement,
}) => {
  const [highlight, setHighlight] = useState<boolean>(false);

  // To keep track of the previous output for comparison
  const prevOutputRef = useRef<string>("");

  useEffect(() => {
    if (barcodeString !== prevOutputRef.current) {
      // barcodeData has changed, set highlight to true
      setHighlight(true);

      // Revert highlight after 500ms
      const timer = setTimeout(() => {
        setHighlight(false);
      }, 500);

      // Clean up the timer
      return () => clearTimeout(timer);
    }

    // Store the previous barcodeData for next comparison
    prevOutputRef.current = `${barcodeString}`;
  }, [barcodeString]);

  return (
    <div className="fixed bottom-0 right-0 bg-blue-100 p-4">
      <p
        style={{
          backgroundColor: highlight ? "yellow" : "transparent",
          transition: "background-color 0.3s ease",
        }}
      >
        barcodeData: {barcodeString}
      </p>
      <p>Current Focused Element: {focusedElement || "none"}</p>
    </div>
  );
};

export default BarcodeIndicator;
