import { useCallback } from "react";
import { RepairModel } from "@/resources/models";
import { printLabel } from "@/hooks/dymo";
import { ToastService } from "@/services";
import {
  IDymoPrintParams,
  IDymoPrintStatus,
  LabelSize,
} from "@/resources/interfaces/dymo.interface";
import { dymoLargeLabel } from "./dymoLabelLarge";
import {useTranslation} from "react-i18next";

export const usePrintLabelsCallback = (
  repair: RepairModel | undefined,
  printers: IDymoPrintStatus[],
) => {
  const { t } = useTranslation();

  async function handlePrintSingleLabel(
    printerName: string,
    printerParams: IDymoPrintParams | null,
    labelXml: string,
    labelSize: LabelSize,
  ) {
    try {
      await printLabel(printerName, labelXml, "", printerParams);
      ToastService.success(
        `Printing ${labelSize} label to ${printerName} complete`,
      );
    } catch (error) {
      ToastService.error(
        `Printing ${labelSize} label to ${printerName} failed`,
      );
    }
  }

  return useCallback(async () => {
    if (!repair || !repair.customer) {
      return;
    }
    const customer = repair.customer;
    const availablePrinters = printers.filter(
      (curr: any) => curr && curr.isConnected.toLowerCase() === "true",
    );
    const qrCodeDataString = JSON.stringify({
      order: repair.id,
    });

    const deliveryMethod = repair.deliveryMethod ?
      t(`common.deliveryMethods.${repair.deliveryMethod}`) ||
      repair.deliveryMethod : '';

    const labelXml = dymoLargeLabel(
      [
        `Klantnummer: ${customer.customerNumber || ""}`,
        `Naam: ${customer.fullName}`,
        `Dossiernummer: ${repair.id}`,
        `Type: ${customer.type}`,
        `Bedrijf: ${customer.companyName}`,
        `Type + jaar: ${repair.brand || ""}`,
        `Leveringsmethode: ${deliveryMethod}`,
        `Opmerking: ${repair.comment}`,
      ].join("\n"),
      qrCodeDataString
    );
    const labelPrinter = availablePrinters[0];
    if (labelPrinter) {
      // Use await because DymoPrintService crashes if to many requests are send at once.
      await handlePrintSingleLabel(
        labelPrinter.name,
        null,
        labelXml,
        "large",
      );
    } else {
      ToastService.warning(`No printer available for large labels`);
    }
  }, [repair, printers, t]);
};
