import {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Chip, CustomDrawer, TextInput} from "@/components";
import {LinkedCameraIcon, MinusIcon, PlusIcon} from "@/icons";
import {DELIVERY_METHOD} from "@/resources/enums";
import {BinModel, ProductFormData, RepairModel} from "@/resources/models";
import {PrismicService, ToastService} from "@/services";
import {useAvailableBins} from "@/hooks/bins/useAvailableBins";
import {BinCard} from "../BinCard";

export interface AddProductsPanelProps {
  opened: boolean;
  order: RepairModel;
  onSubmit(binId: number, products: ProductFormData[]): void;
  onClose(): void;
}

function expandProductsByCount(arr: ProductFormData[]): ProductFormData[] {
  let result: ProductFormData[] = [];

  arr.forEach(item => {
    for (let i = 0; i < item.count; i++) {
      result.push({ ...item, count: 1 });  // Create a copy of the object
    }
  });

  return result;
}

const AddProductsPanel: FC<AddProductsPanelProps> = ({
  opened,
  order,
  onSubmit,
  onClose,
}) => {
  const { t } = useTranslation();

  const [products, setProducts] = useState<ProductFormData[]>([]);
  const [bin, setBin] = useState<BinModel>();
  const bins = useAvailableBins(opened, order.deliveryMethod as DELIVERY_METHOD, t);
  const [showBinsSelect, setShowBinsSelect] = useState(false);

  useEffect(() => {
    PrismicService.searchProducts(false).then((data) => {
      const products = data.map((item) => ({
        name: item.name,
        price: item.price,
        image: item.image?.url,
        count: 0,
        cmsProductId: item.product_id || '',
      }));
      setProducts(products);
    }).catch((err) => {
      ToastService.showHttpError(err, t('repairs.loadingProductsFailed'));
    });
  }, [t]);

  useEffect(() => {
    setBin(undefined);
    setProducts((prev) => prev.map((item) => ({
      ...item,
      count: 0,
    })));
  }, [opened]);

  const onSelectBin = (bin?: BinModel) => {
    if (bin) {
      setBin(bin);
      setShowBinsSelect(false);
    }
  };

  const onChangeCount = (product: ProductFormData, count: number) => {
    setProducts(
      products.map((item) => (
        item.name === product.name ? { ...item, count } : item
      ))
    );
  };

  const selectedProducts = products.filter((item) => item.count > 0);
  const isValid = Boolean(bin && selectedProducts.length);

  const onSave = () => {
    if (!isValid) {
      return;
    }
    // Split selectedProducts with count larger larger then 1 into multiple
    // selected product.
    onSubmit(bin?.id as number, expandProductsByCount(selectedProducts));
  };

  return (
    <CustomDrawer
      isOpen={opened}
      title="Onderdeel inboeken"
      size="xl"
      position="end"
      contentClass="p-8"
      headerActions={(
        <button className="btn btn-blue" disabled={!isValid} onClick={onSave}>
          {t('repairs.bookInBin')}
        </button>
      )}
      onClose={onClose}
    >
      {(!bin || showBinsSelect) ? (
        <>
          <h6 className="font-bold">{t('repairs.selectBinFirst')}</h6>
          <div className="flex flex-wrap items-stretch gap-6 mt-4">
            {bins.map((item) => (
              <BinCard activeBin={bin} binInfo={item} onSelect={onSelectBin} />
            ))}
          </div>
        </>
      ) : (
        <>
          <h6 className="font-bold mb-2">{t('common.binNo')}</h6>
          <div className="form-field">
            <TextInput
              name="bin"
              className="max-w-130"
              value={bin.name}
              fullWidth
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <LinkedCameraIcon
                    className="text-blue cursor-pointer"
                    onClick={() => setShowBinsSelect(true)}
                  />
                )
              }}
            />
          </div>

          <hr className="bg-blue-200 my-8" />

          <h6 className="font-bold">{t('common.choosePart')}</h6>
          <div className="mt-6">
            {products.map((product) => (
              <div key={product.name} className="h-22 flex items-center border border-secondary rounded pr-8 mt-2">
                <div
                  className="w-29 h-full bg-blue-100 bg-center bg-contain bg-no-repeat rounded"
                  style={{ backgroundImage: `url(${product.image})` }}
                />
                <div className="flex-1 text-max-lines-3 break-words mx-8">{product.name}</div>
                {product.count > 0 && (
                  <Chip theme="success" size="sm" className="mr-8">{`${product.count}x`}</Chip>
                )}
                {product.count > 0 && (
                  <button className="btn icon-btn mr-2" onClick={() => onChangeCount(product, product.count - 1)}>
                    <MinusIcon />
                  </button>
                )}
                <button className="btn icon-btn" onClick={() => onChangeCount(product, product.count + 1)}>
                  <PlusIcon />
                </button>
              </div>
            ))}
          </div>
        </>
      )}
    </CustomDrawer>
  );
}

export default AddProductsPanel;
