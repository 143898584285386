import {FC, useState} from "react";
import {useTranslation} from "react-i18next";
import {DataTable, IDataTableColumn, ISortModel} from "@/components";
import {OrderLogModel} from "@/resources/models";
import {formatDate} from "@/utils/helpers";

interface IFilter {
  sort: ISortModel[];
}

export interface IRepairHistoryTableProps {
  className?: string;
  tableData: OrderLogModel[];
}

const RepairHistoryTable: FC<IRepairHistoryTableProps> = ({
  className,
  tableData,
}) => {
  const { t } = useTranslation();

  const [filter, setFilter] = useState<IFilter>({
    sort: [],
  });

  const tableColumns: IDataTableColumn[] = [
    {
      title: t('common.timestamp') || 'Timestamp',
      field: 'timestamp',
      cellClass: '!text-blue-400',
      sortable: true,
      render(data: OrderLogModel) {
        return formatDate(data.createdAt, 'full');
      },
    },
    {
      title: t('common.action') || 'Action',
      field: 'action',
      cellClass: 'font-semibold',
      sortable: true,
      getText(data: OrderLogModel) {
        return data.getActionText();
      }
    },
    {
      title: `${t('common.workTable')} / ${t('common.bin').toLowerCase()}`,
      field: 'workTable',
      sortable: true,
      getText(data: OrderLogModel) {
        return data.worktable || '';
      }
    },
    {
      title: t('common.description') || 'Description',
      field: 'description',
      sortable: true,
      getText(data: OrderLogModel) {
        return data.getDescription();
      },
      render(data: OrderLogModel) {
        const text = data.getDescription();
        return (
          <div dangerouslySetInnerHTML={{ __html: text }} />
        );
      }
    },
    {
      title: t('common.colleague') || 'Colleague',
      field: 'employerId',
      sortable: true,
      getText(data: OrderLogModel) {
        return data.getEmployerName();
      }
    },
  ];

  const onFilterChange = (field: string, value: any) => {
    setFilter((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <DataTable
      className={className}
      columns={tableColumns}
      data={tableData}
      cellClass="!px-2 !py-3"
      headerCellClass="!px-2 !py-3"
      serverSide={false}
      sortModel={filter.sort}
      onSort={(model) => onFilterChange('sort', model)}
    />
  );
};
export default RepairHistoryTable;
