import { RepairModel } from "@/resources/models";
import { OrderImageModel } from "@/resources/models/order-image.model";
import { OrderImagesService } from "@/services/order-images.service";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Trans } from "react-i18next";
import Thumbnail from "./Thumbnail";

interface UploadProps {
  repair: RepairModel;
  orderImages: Array<OrderImageModel> | null;
  onSelect: (orderImage: OrderImageModel) => void;
  onAdd: (orderImages: Array<OrderImageModel> | null) => void;
  className?: string;
}

const Uploads: React.FC<UploadProps> = ({
  repair,
  orderImages,
  className,
  onSelect,
  onAdd,
}) => {
  const onDrop = useCallback(
    async (acceptedFiles: Array<File>) => {
      const result = await OrderImagesService.add(repair.id, acceptedFiles);
      onAdd(result);
    },
    [repair, onAdd]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: { "image/*": [] },
    maxFiles: 10,
    onDrop,
  });

  return (
    <div className={className}>
      <div className="flex flex-wrap gap-2">
        {/** IMAGES */}
        {orderImages &&
          orderImages.map((orderImage) => (
            <Thumbnail
              key={orderImage.id}
              orderImage={orderImage}
              onClick={() => {
                onSelect(orderImage);
              }}
            />
          ))}
      </div>
      {/** DROPZONE */}
      <div
        {...getRootProps()}
        className="border-2 border-dashed border-gray-300 p-6 rounded-lg text-center content-center mt-4"
      >
        <input {...getInputProps()} />
        <p className="text-gray-500">
          <Trans
            i18nKey="common.image-file-dropzone"
            count={orderImages?.length}
          />
        </p>
      </div>
    </div>
  );
};

export default Uploads;
