import { useState, useEffect, useCallback } from "react";
import debounce from "lodash/debounce";
import BarcodeInputProcessor from "./BarcodeInputProcessor";
import { BarcodeDataKey } from "./types";

interface BarcodeListenerProps {
  onChange: (barcodeData: Record<BarcodeDataKey, string>) => void;
}

const BarcodeListener: React.FC<BarcodeListenerProps> = ({ onChange }) => {
  const [, setBuffer] = useState<string[]>([]);

  const handleBarcodeScan = (barcodeData: string[]) => {
    // Process the barcode data and check for JSON match
    const result = BarcodeInputProcessor.process(barcodeData) as Record<
      BarcodeDataKey,
      string
    > | null;

    if (result) {
      onChange(result);
    }
  };

  // Debounced function to process the final input
  const processBuffer = useCallback(
    debounce((input: string[]) => {
      // Process barcode data on Enter key press
      handleBarcodeScan(input);
      setBuffer([]); // Reset buffer after processing
    }, 250), // 250ms timeout for burst detection
    [setBuffer]
  );

  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      // Ignore modifier keys (Shift, Meta, Control, Alt) but allow Enter
      // WebBrowser prefill/autosuggest can result in an undefined event.key
      if (
        !event.key ||
        (typeof event.key === "string" &&
          event.key.length > 1 &&
          event.key !== "Enter")
      ) {
        return;
      }

      // Update buffer and call debounced function
      setBuffer((prev) => {
        const newBuffer = [...prev, event.key];
        processBuffer(newBuffer);
        return newBuffer;
      });
    };

    window.addEventListener("keydown", handleKeydown);
    return () => {
      window.removeEventListener("keydown", handleKeydown);
      processBuffer.cancel(); // Clean up debounce
    };
  }, [processBuffer]);

  return null;
};

export default BarcodeListener;
