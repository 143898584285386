import { OrderImageModel } from "@/resources/models/order-image.model";
import { HttpService } from "./http.service";

export class OrderImagesService {
  static async findForOrder(
    orderId: string,
    showSpinner = false
  ): Promise<Array<OrderImageModel> | null> {
    const orderImages = await HttpService.get(
      `/orders/${orderId}/uploads`,
      {},
      showSpinner
    );
    if (Array.isArray(orderImages)) {
      return orderImages.map((item: any) => new OrderImageModel(item, orderId));
    }
    return null;
  }

  static async add(
    id: string,
    data: File[]
  ): Promise<Array<OrderImageModel> | null> {
    const order = await HttpService.upload(`/orders/${id}/uploads`, data);

    if (Array.isArray(order?.images)) {
      return order.images.map((item: any) => new OrderImageModel(item, id));
    }
    return null;
  }

  static async remove(orderId: string, uploadId: string) {
    HttpService.delete(`/orders/${orderId}/uploads/${uploadId}`);
  }
}
